import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { PdfFile } from "../../../../types/Content"
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { TextSnippetOutlined, MoreHoriz, Visibility, VisibilityOff, Edit, DeleteForever, AccessTime, Warning, FileDownloadOutlined, Insights } from '@mui/icons-material'

import { Op_deletePdfFile, Op_editPdfFile } from "../../../../types/Ops"
import { Link } from "wouter"

import EditPdfFileDialog from "./EditPdfFileDialog"
import { PodClass } from "../../classes/Pod"
import ConfirmDialog from "../Elements/ConfirmDialog"
import DownloadOptions from "./DownloadOptions"
import ReadingProgress from "../Elements/ReadingProgress"

// message from thread
const PdfFileLine = ({pdfFile}: {pdfFile: PdfFile}) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ showEditDialog, setShowEditDialog ] = React.useState<boolean>(false)
  const [ showDeleteDialog, setShowDeleteDialog ] = React.useState<boolean>(false)
  const [ showDownloadDialog, setShowDownloadDialog ] = React.useState<boolean>(false)
  const [ showStats, setShowStats ] = React.useState<boolean>(false)
  const { podStore, sessionStore, opStore } = useStore()
  const { t } = useTranslation()
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (event:React.MouseEvent) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const toggleVisibility = () => {
    const op:Op_editPdfFile = {
      op: 'editPdfFile',
      podId: pod.podId,
      data: {
        nodeId: pdfFile.nodeId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
        mods: {
          hidden: (pod.content.pdfFiles[pdfFile.nodeId].hidden) ? false : true
        }
      }
    }
    opStore.doOp(op)
  }

  const scheduleDeleteFile = () => {
    const op:Op_deletePdfFile = {
      op: 'deletePdfFile',
      podId: pod.podId,
      data: {
        nodeId: pdfFile.nodeId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }

  const clickable = (pdfFile.status === 'complete') && (!pdfFile.hidden || pdfFile.userId === sessionStore.session.user.userId)

  var visibility = {
      style: { opacity: 1, cursor: 'default' },
      text: t('Hide from regular pod users'),
      icon: <VisibilityOff />
  }

  if (pdfFile.hidden) visibility = {
    style: { opacity: .3, cursor: 'default' },
    text: t('Show to regular pod users'),
    icon: <Visibility />
  }

  if (clickable) visibility.style.cursor = 'pointer'

  const interactionStats = (nodeId:string) => {
    const file = pod.content.pdfFiles[nodeId]
    type tupel = {[name: string]: number}
    const matrix:{[userId: number]: tupel} = {}
    const increment = (i:string, u:number) => {
      if (typeof matrix[u] === 'undefined') matrix[u] = { comment:0, message:0, link:0, weblink:0, tagging:0, emotion:0 }
      matrix[u][i]++
    }

    Object.keys(file.comments).forEach(interactionId => { const interaction = file.comments[interactionId]; increment(interaction.interactionType, interaction.userId) })
    Object.keys(file.links).forEach(interactionId =>    { const interaction = file.links[interactionId];    increment(interaction.interactionType, interaction.userId) })
    Object.keys(file.weblinks).forEach(interactionId => { const interaction = file.weblinks[interactionId]; increment(interaction.interactionType, interaction.userId) })
    Object.keys(file.taggings).forEach(interactionId => { const interaction = file.taggings[interactionId]; increment(interaction.interactionType, interaction.userId) })
    Object.keys(file.emotions).forEach(interactionId => { const interaction = file.emotions[interactionId]; increment(interaction.interactionType, interaction.userId) })
    Object.keys(pod.content.threads).forEach((threadId) => {
      const interaction = pod.getInteractionFromThreadId(threadId)
      if (interaction?.anchor.nodeId === file.nodeId) pod.content.threads[threadId].messages.forEach(m => increment('message', m.userId))
    })

    if (!Object.keys(matrix).length) return <div>No Interactions</div>

    const userIds:number[] = Object.keys(matrix) as unknown as number[]

    return <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>User</TableCell>
          <TableCell>Comments</TableCell>
          <TableCell>Replies</TableCell>
          <TableCell>Emotions</TableCell>
          <TableCell>Tags</TableCell>
          <TableCell>Weblinks</TableCell>
          <TableCell>Links</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { userIds.map((userId) => <TableRow key={userId}>
          <TableCell>{pod.userInfos[userId].userName}</TableCell>
          <TableCell>{matrix[userId]['comment'] || ''}</TableCell>
          <TableCell>{matrix[userId]['message'] || ''}</TableCell>
          <TableCell>{matrix[userId]['emotion'] || ''}</TableCell>
          <TableCell>{matrix[userId]['tagging'] || ''}</TableCell>
          <TableCell>{matrix[userId]['weblink'] || ''}</TableCell>
          <TableCell>{matrix[userId]['link']    || ''}</TableCell>
        </TableRow>) }
      </TableBody>
    </Table>
  }


  var menu = null
  var menuItems:JSX.Element[] = []
  const podAllowsDownload = Boolean(pod.allowDownload.indexOf('pdf')>-1)

  if (pod.isAllowed('editPdfFile', pdfFile.nodeId) && (pdfFile.status === 'complete')) menuItems.push(<MenuItem key='visibility' onClick={(e:React.MouseEvent) => { toggleVisibility(); handleClose(e); e.stopPropagation() }}>
    <ListItemIcon>
      {visibility.icon}
    </ListItemIcon>
    <ListItemText>{visibility.text}</ListItemText>
  </MenuItem>)

  if (pod.isAllowed('editPdfFile', pdfFile.nodeId) && (pdfFile.status === 'complete')) menuItems.push(<MenuItem key='edit' onClick={(e:React.MouseEvent) => { setShowEditDialog(true); handleClose(e); e.stopPropagation() }}>
    <ListItemIcon>
      <Edit />
    </ListItemIcon>
    <ListItemText>{t('Edit this file')}</ListItemText>
  </MenuItem>)

  if ((podAllowsDownload) && (pdfFile.status === 'complete')) menuItems.push(<MenuItem key='download' onClick={(e:React.MouseEvent) => { setShowDownloadDialog(true) ; handleClose(e); e.stopPropagation() }}>
    <ListItemIcon>
      <FileDownloadOutlined />
    </ListItemIcon>
    <ListItemText>{t('Download this file')}</ListItemText>
  </MenuItem>)

  if (sessionStore.session.user.userId === 4) menuItems.push(<MenuItem key='stats' onClick={(e:React.MouseEvent) => { setShowStats(true) ; handleClose(e); e.stopPropagation() }}>
  <ListItemIcon>
    <Insights />
  </ListItemIcon>
  <ListItemText>{t('File statistics')}</ListItemText>
</MenuItem>)

  if (pod.isAllowed('deletePdfFile', pdfFile.nodeId) && ((pdfFile.status === 'complete') || (pdfFile.status === 'broken') || (pdfFile.status === 'scheduled'))) menuItems.push(<nav key="deldiv" aria-label="dangerous delete option">
    <Divider style={{margin:'8px 0'}} />
    <MenuItem key='delete' onClick={(e:React.MouseEvent) => { setShowDeleteDialog(true); handleClose(e); e.stopPropagation() }}>
      <ListItemIcon>
        <DeleteForever sx={{color:"darkred"}} />
      </ListItemIcon>
      <ListItemText sx={{color:"darkred"}}>{t('Delete this file')}</ListItemText>
    </MenuItem>
  </nav>)

  const showMenu = (menuItems.length)

  const sx = {
    overflow:'hidden',
    height: "65px",
    display: "grid",
    gridTemplateColumns: showMenu ? "min-content 1fr auto auto min-content" : "min-content 1fr auto auto",
    gridGap: "10px",
    alignItems: "center",
    p: "5px 16px",
    mt: 1,
    mb: 1,
    "&:hover": { backgroundColor: "whitesmoke" }
  }

  if (showMenu) menu = <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
    }}
    >
      {menuItems}
    </Menu>

  var statusIndicator = null

  if (pdfFile.status === 'scheduled') statusIndicator = <span style={{color:'orange', verticalAlign:'middle'}}>
    <AccessTime style={{verticalAlign:'middle', marginRight:4}} />
    Scheduled to be processed
  </span>
  if (pdfFile.status === 'processing') statusIndicator = <span style={{color:'orange', verticalAlign:'middle'}}>
    <AccessTime style={{verticalAlign:'middle', marginRight:4}} />
    Processing ({Math.min(pdfFile.pages.length, pdfFile.nofPages)} / {pdfFile.nofPages})
  </span>
  if (pdfFile.status === 'broken') statusIndicator = <span style={{color:'red', verticalAlign:'middle'}}>
    <Warning style={{verticalAlign:'middle', marginRight:4}} />
    Broken
  </span>

  var entry = <Paper style={visibility.style} elevation={2} sx={sx}>
    <TextSnippetOutlined />
    <div style={{whiteSpace:'nowrap', overflow: 'hidden'}}>
      { pdfFile.name }
    </div>
    {!statusIndicator &&
      <ReadingProgress nodeId={pdfFile.nodeId} />
    }
    <div style={{textAlign:'right'}}>{ statusIndicator }</div>
    {
    (showMenu) ?
    <IconButton size='small' onClick={(e:React.MouseEvent) => { handleClick(e)} }>
      <MoreHoriz fontSize='small' />
    </IconButton> : null
    }
  </Paper>

  if (clickable) entry = <Link to={`/pod/${pod.podId}/pdf/${pdfFile.nodeId}`}>{entry}</Link>

  return <>
    {entry}
    {menu}
    <EditPdfFileDialog pdfFile={pdfFile} open={showEditDialog} handleClose={() => { setShowEditDialog(false)}} />
    <DownloadOptions nodeId={pdfFile.nodeId} open={showDownloadDialog} handleClose={() => { setShowDownloadDialog(false)}} />
    <ConfirmDialog title={t('Permanently Delete this File?')}
      info={t('Deleting this file will also delete all interactions and conversations it contains. It cannot be undone. Are you sure?') ||''}
      open={showDeleteDialog}
      handleClose={() => { setShowDeleteDialog(false)} }
      onOk={scheduleDeleteFile} />
    <Dialog open={showStats} onClose={() => { setShowStats(false) }}>
      <DialogTitle>{t('Statistics')}</DialogTitle>
      <DialogContent>
        {showStats ? interactionStats(pdfFile.nodeId) : null}
      </DialogContent>
    </Dialog>
  </>
}

export default observer(PdfFileLine)