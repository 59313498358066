import { makeObservable, action, observable } from "mobx"
import throttle from '@jcoreio/async-throttle'

import exportSessionStore from './sessionStore'
import exportBroadcastStore from "./broadcastStore"
import alertStore, { alert } from '../stores/alertStore'
import { PodClass } from '../classes/Pod'
import podStore from './podStore'
import api from '../api/api'
import exportUiStore from "./uiStore"
import { Pod, PodLoadState, syncPullRequest } from "../../../types/Pod"
import { Op, OpSkeleton, noOp } from "../../../types/Ops"
import { UserInfo } from "../../../types/User"
import i18next from 'i18next'
import dayjs from "dayjs"


export interface OpStoreModel {
  doOp: (op: OpSkeleton) => void,
  syncFull: () => void,
  static: { busy: boolean, queue: Array<Op>, swStatus: number },
  onUnload: (e:any) => void,
}


class OpStore {

  static: { busy: boolean, queue: Op[], swStatus:number } = {
    busy: false,
    queue: [],
    swStatus: 0,
  }

  constructor() {
    makeObservable(this, {
      static: observable,
      importOps: action,
      setSyncBusy: action,
      getSyncableOps: action,
      setSyncables: action,
      doOp: action,
      refreshSessionPodWithRemoteInfo: action,
      execute: action,
    })
  }

  onUnload(e:any) {
    if(exportUiStore.showVerboseLogging.sync) console.log('trying to unload')
    e.preventDefault()
  }


  setSyncBusy(busy:boolean) {
    this.static.busy = busy
  }

  getSyncableOps(watermark:number) {
    return this.static.queue.splice(0, watermark)
  }

  setSyncables(syncables: Op[]) {
    this.static.queue.unshift(...syncables)
  }

  setSwStatus(queueLength: number) {
    this.static.swStatus = queueLength
  }

  async refreshSessionPodWithRemoteInfo(podId:string) {
    const loadedPod = await api.loadPod(podId)
    const sessionPods = exportSessionStore.session.pods
    const podIndex = sessionPods.findIndex((p:any) => p.podId === podId)
    if ((loadedPod) && (podIndex>-1) && (sessionPods[podIndex].status === 'initialized')) {
      exportSessionStore.setPod(podId, loadedPod)
    }
    else {
      console.warn(`did not init pod: ${loadedPod} ${podIndex} ${sessionPods[podIndex].status}`)
    }
  }

  /**
   * Import operations that have been delivered via broadcast (from another tab / from the serviceWorker)
   */
  importOps(ops: Op[], clientUpdates: Op[]) {
    // Apply the ops to the currently loaded pod
    if (ops) ops.forEach((op:any) => {
      this.execute(podStore.pod, op)
      podStore.addToPodActivity(op.podId, op)
    })

    // Apply the client-wide Ops
    if (clientUpdates) clientUpdates.forEach((op:any) => {
      this.execute(podStore.pod, op)
    })

    // Re-Apply all local ops that have not yet been transmitted to the ServiceWorker (if applicable) or the backend
    this.static.queue.forEach((op:Op) => {
      if(exportUiStore.showVerboseLogging.sync) console.log(`reapply ${op.op}`)
      this.execute(podStore.pod, op)
    })
  }

  syncFull() {
    this.throttledSyncFull()
  }

  throttledSyncFull = throttle(async () => {
    this.setSyncBusy(true)
    const watermark = this.static.queue.length
    var syncables = this.getSyncableOps(watermark)

    if (!exportSessionStore.session?.sessionId) return false

    try {
      var pulling:Array<syncPullRequest> = []
      if ((podStore.pod?.podId) && (podStore.pod?.status === 'loaded' as PodLoadState)) pulling = [{podId: podStore.pod.podId, lastSyncOid: podStore.pod.lastSyncOid, tRequested: dayjs().unix()}]

      const res = await api.syncFull(pulling, syncables, exportSessionStore.session.sessionId, exportSessionStore.session.clientLastSyncOid)

      if ((res) && (res.status === 401)) {
        if(exportUiStore.showVerboseLogging.sync) console.log('API.syncFull() resulted in 401')
        exportSessionStore.clearSession()

        alertStore.push(alert(i18next.t('Your session has expired. Please log in again'), 'warning'))
        exportBroadcastStore.sendMessage({op: "logout"})
        this.setSyncables(syncables)
      } else if ((res) && (res.status === 202)) {
        if (exportUiStore.showVerboseLogging.sync) console.log('API.syncFull() was handled by serviceWorker')
        // Check if pushing the ops to the SW was successful
        const pushResults = res.body.pushResults
        const failedOps = syncables.filter((op: Op) => {
          if (!op.opLogId) throw new Error('Typescript: Cannot sync operation without opLogId')
          return pushResults[op.opLogId]?.ok !== true
        })
        if (failedOps.length) {
          syncables = failedOps // because the indexedDB does not (yet) handle opLogId collisions, we retry only the ops that failed (this should not happen anyway)
          throw new Error(`Frontend: syncPush to the ServiceWorker contained ${failedOps.length} failed operations. PANIC.` + JSON.stringify(failedOps))
        }

      }
      else if ((res) && (res.status === 200)) {
        console.warn('The API Sync-call seems to have been handled by the backend directly (without a Service Worker).')

        // Process the returns without having a serviceWorker
        const { podUpdates, pushResults, clientUpdates, digest }:{podUpdates:{[podId: string]: Op[]}, pushResults:{[opLogId:string]: any}, clientUpdates:Op[], digest:null|string } = res.body
        const [ backendFingerprintPodId, backendFingerprint] = digest ? digest.split(' ') : ['', '']

        // Check if pushing was successful
        const failedOps = syncables.filter((op: Op) => {
          if (!op.opLogId) throw new Error('Typescript: Cannot sync operation without opLogId')
          return pushResults[op.opLogId]?.ok !== true
        })
        if (failedOps.length) {
          throw new Error(`Frontend: syncPush without ServiceWorker contained ${failedOps.length} failed operations. PANIC.` + JSON.stringify(failedOps))
        }

        // Apply the incoming Ops
        if (podUpdates) {
          try {

            for (var podUpdateNo=0; podUpdateNo<Object.keys(podUpdates).length; podUpdateNo++) {
              const podId = Object.keys(podUpdates)[podUpdateNo]
              const ops:Op[] = podUpdates[podId]

              const filteredOps: any[] = ops.filter((op:any) => op.podId === podId)

              filteredOps.forEach((op:Op) => {
                if (podStore.pod && (podStore.pod.podId === op.podId)) {
                  this.execute(podStore.pod, op)
                }
                else {
                  this.execute(null, op)
                }
              })

              if (podStore.pod && (podStore.pod.podId === podId) && (syncables.length === 0) && (this.static.queue.length === 0)) {
                const fingerprint = podStore.pod.fingerprint(true)
                if ((podId === backendFingerprintPodId) && (clientUpdates.length === 0) && (filteredOps.length === 0)) {

                  if (fingerprint !== backendFingerprint) {
                    console.warn(`Remote fingerprint ${backendFingerprint} does not match local fingerprint ${fingerprint}`, syncables, this.static.queue, res)
                    if (!podStore.pod.outOfSync) {
                      podStore.setOutOfSync(true)
                      // notify the backend for debugging and send the long-form fingerprint
//                      api.reportDivergentFingerprint(podId, exportSessionStore.session.sessionId, podStore.pod.fingerprint(false))
                    }
                  }
                  else {
                    if(exportUiStore.showVerboseLogging.sync) console.log(`Successfully verified fingerprint digest ${backendFingerprint} for ${podId}`)
                    if (podStore.pod.outOfSync) {
                      podStore.setOutOfSync(false)
                    }
                  }
                }
                // Updates to the sessionPods are handled directly by this.execute (@todo: verify)
              }
            }
          } catch (e:any) {
            // report and rethrow errors to make sure that the queue gets restored
            console.error(e);
            throw new Error(e)
          }
        }

        if (clientUpdates.length) {
          //@todo: handle clientUpdates
          console.warn(`${clientUpdates.length} undhandled client updates`)
        }

        // Apply all unsynced ops over the incoming ones
        const unsynced = [...this.static.queue]
        if (unsynced.length) {
          if(exportUiStore.showVerboseLogging.sync) console.log(`Apply ${unsynced.length} Ops after syncing without a service worker`)
          unsynced.forEach((op:Op) => {
            if (podStore.pod && (podStore.pod.podId === op.podId)) {
              this.execute(podStore.pod, op)
            }
            else {
              this.execute(null, op)
            }
          })
        }

      }
      else {
        throw new Error('API call to syncFull resulted in status:' + res?.status)
      }
    }
    catch(e) {
      console.error(e, 'Resetting Client-syncables to ', syncables)
      this.setSyncables(syncables)
    }
    this.setSyncBusy(false)
  }, 2000, { } )

  /** Apply single operation to a given Pod
   *  Include side-effects, such as updating the Pod overview, when the Pod's name etc. changes
   */
  execute(pod: PodClass | null, op: Op|noOp) {

    switch(op.op) {

      // All these are pure pod operations with no side effects outside the pod
      case 'noop':
      case 'addAnnotation':
      case 'addComment':
      case 'addEmotion':
      case 'addLink':
      case 'addReadingQuestion':
      case 'addTagging':
      case 'addWeblink':
      case 'addFolder':
      case 'addPdfFile':
      case 'addTag':
      case 'editPdfFile':
      case 'editFolder':
      case 'addPdfPage':
      case 'editAnnotation':
      case 'editComment':
      case 'editEmotion':
      case 'editLink':
      case 'editReadingQuestion':
      case 'editTagging':
      case 'editWeblink':
      case 'deletePdfFile':
      case 'deleteFolder':
      case 'deleteAnnotation':
      case 'deleteComment':
      case 'deleteEmotion':
      case 'deleteLink':
      case 'deleteReadingQuestion':
      case 'deleteTagging':
      case 'deleteWeblink':
      case 'addThread':
      case 'addMessage':
      case 'deleteMessage':
      case 'editMessage':
      case 'addViews':
      case 'addReaction':
      case 'deleteReaction':

        if ((pod) && (pod.podId === op.podId)) {
          pod.applyOp(op)
        } else {
          // console.warn(`Did not execute ${op.op} because the corresponding pod ${op.podId} was not present:`, op)
        }

        break;

      // leave and join operations (also) need to update the session, as do editUserInfos
      case 'editUserInfo': {
        if ((pod) && (pod.podId === op.podId)) { pod.applyOp(op) }
        const session = exportSessionStore.session
        const podinfo = session.pods.find((p:any) => p.podId === op.podId)
        if (podinfo) {
          if ((podinfo.userInfos) && (podinfo.userInfos[op.data.userId])) {
            podinfo.userInfos[op.data.userId].userName = op.data.mods.userName
            podinfo.userInfos[op.data.userId].color = op.data.mods.color
          }
        }
        exportSessionStore.setSession(session)
        } break

      case 'removeUserFromPod':
        if (op.data.userId === exportSessionStore.session.user.userId) {
          if(exportUiStore.showVerboseLogging.sync) console.log(`RemoveUserFromPod: Remove pod ${op.podId} from CLIENT SESSION (because the leaving user is user ${op.data.userId})`)
          const sessionPods = exportSessionStore.session.pods.filter((pod: Pod) => pod.podId !== op.podId)
          exportSessionStore.setPods(sessionPods)
          if (pod) podStore.setPodStatus(pod, 'unknown')
          if (op.oid) exportSessionStore.setClientLastSyncOid(op.oid)
        }
        else {
          if ((pod) && (pod.podId === op.podId)) pod.applyOp(op)
          const session = exportSessionStore.session
          const podinfo = session.pods.find((p:any) => p.podId === op.podId)
          if (podinfo && podinfo.userInfos) {
            if(exportUiStore.showVerboseLogging.sync) console.log(`RemoveUserFromPod: Remove user ${op.data.userId} from the userlist of pod ${op.podId} in the CLIENT SESSION)`)
            delete podinfo.userInfos[op.data.userId]
          }
        }
        break

      case 'addUserToPod':
        if (op.data.userId === exportSessionStore.session.user.userId) {
          const sessionPods = exportSessionStore.session.pods
          const podIndex = sessionPods.findIndex((p:any) => p.podId === op.podId)
          if(exportUiStore.showVerboseLogging.sync) console.log(`Processing addUserToPod with op.data.type ${op.data.type}`)
          var newStatus:PodLoadState = 'unknown'
          if (op.data.type === 'joined') newStatus = 'initialized'
          if (op.data.type === 'request') newStatus = 'requested'
          if (podIndex === -1) {
            sessionPods.push({
              podId: op.podId,
              name: op.data.podName,
              status: newStatus
            })
          }
          else {
            newStatus = sessionPods[podIndex].status
            if ((sessionPods[podIndex].status === 'requested') && (op.data.type === 'joined')) newStatus = 'initialized'
            if ((sessionPods[podIndex].status === 'requested') && (op.data.type === 'rejected')) newStatus = 'unknown'
            sessionPods[podIndex] = {
              podId: op.podId,
              name: op.data.podName,
              status: newStatus
            }
          }
          if (newStatus === 'initialized') this.refreshSessionPodWithRemoteInfo(op.podId)
          if (op.data.type === 'rejected') {
            exportSessionStore.setPods(sessionPods.filter((pod: Pod) => pod.podId !== op.podId))
            alertStore.push(alert('Access to pod ' + op.podId + ' was not granted', 'warning'))
          }
          else {
            exportSessionStore.setPods(sessionPods)
            if (op.data.type === 'joined') alertStore.push(alert('You now have access to pod ' + op.podId + '', 'success'))
          }
          if (pod) podStore.setPodStatus(pod, newStatus)
          if (op.oid) exportSessionStore.setClientLastSyncOid(op.oid)
        }
        else {
          if ((pod) && (pod.podId === op.podId)) pod.applyOp(op)
          const sessionPods = exportSessionStore.session.pods
          const podIndex = sessionPods.findIndex((p:any) => p.podId === op.podId)
          const userInfo:UserInfo = {
            userId: op.data.userId,
            login: op.data.login,
            userName: op.data.userName,
            color: op.data.color,
          }
          sessionPods[podIndex].userInfos[op.data.userId] = userInfo
          exportSessionStore.setPods(sessionPods)
        }
        break

      case 'editPermission': {
        if ((pod) && (pod.podId === op.podId)) pod.applyOp(op)
        const sessionPods = exportSessionStore.session.pods
        const podIndex = sessionPods.findIndex((p:any) => p.podId === op.podId)
      } break

      case 'editPod': {
        if ((pod) && (pod.podId === op.podId)) pod.applyOp(op)
        const sessionPods = exportSessionStore.session.pods
        const podIndex = sessionPods.findIndex((p:any) => p.podId === op.podId)
        if (podIndex >- 1 && typeof op.data.mods.name !== 'undefined') sessionPods[podIndex].name = op.data.mods.name
      } break

      case 'deletePod':
        if(exportUiStore.showVerboseLogging.sync) console.log(`deletePod(${op.podId}) will remove the pod from the session`)
        const sessionPods = exportSessionStore.session.pods.filter((pod: Pod) => pod.podId !== op.podId)
        exportSessionStore.setPods(sessionPods)
        if ((pod) && (pod.podId === op.podId)) podStore.setPodStatus(pod, 'deleted')
        alertStore.push(alert('Pod ' + op.podId + ' was deleted', 'warning'))
        break

      default:
        console.warn(`Unknown op ${op.op}`)
    }
  }

  /** Process an operation. This includes
   *  1) adding missing information to make sure it is a complete OP
   *  2) executing it, which will include side effects
   *  3) syncing it to the ServiceWorker / Backend
   */
  doOp(miniOP: OpSkeleton) {

    const op:Op = miniOP as Op

    // add missing information
    if (!op.opLogId)   op.opLogId  = exportSessionStore.createUuid()
    op.tCreated = dayjs().unix()

    // Since addOperations contain the original object to be added, make sure its coid is set (as null)
    switch (op.op) {
      case 'addPdfFile':
      case 'addPdfPage':
      case 'addFolder':
      case 'addAnnotation':
      case 'addComment':
      case 'addLink':
      case 'addWeblink':
      case 'addTag':
      case 'addEmotion':
      case 'addReadingQuestion':
      case 'addThread':
      case 'addMessage':
        if (!op.data.coid) op.data.coid = null
        if (!op.data.tCreated) op.data.tCreated   = null
        if (!op.data.tModified) op.data.tModified = null
    }

    // Execute the op on the loaded podStore (or on null) for possible side effects
    if (podStore.pod !== null) this.execute(podStore.pod, op); else this.execute(null, op)

    // Queue this OP for syncing (to the SW and, eventually, the backend)
    this.static.queue.push(op)
    exportBroadcastStore.sendMessage({op: 'syncMsg', ops: [op]})
    this.throttledSyncFull()
  }

}

const exportOpStore = new OpStore()
export default exportOpStore